<template>
 

  <div :class="{'cfrm': true}">

    <div class="input">  
      <label for="chat_message_unput" class="hlabel">Comment</label> 
      <textarea 
        :style="'height: ' + calc_height + 'px'"
        placeholder="Your message..." 
        v-model="text"
        @keydown.enter.exact.prevent="send"
        @keydown.enter.shift.exact.prevent="message += '\n'"
        ></textarea>

      <div class="calcheight" ref="el_calc_height">
        {{ text }}
      </div>
    </div>

    <div class="send_btn" @click="send">
      <div class="is_loading"></div>
      <i class="icon-message is_ready"></i>
    </div>
    
  </div>

</template>


<script setup>

const { $api, $popup } = useNuxtApp();
const emits = defineEmits(['closed', 'created', 'updated']);


const props = defineProps({
  type: {
    type: String
  },
  text: {
    type: String,
    default: ''
  },
  content: {
    type: Number
  }, 
  editedId: {
    type: Number
  },  
  parentId: {
    type: Number
  },
  replyId: {
    type: Number
  },
  replyToUserName: {
    type: String
  },
})

const el_calc_height = ref(null)
const calc_height = ref(40)

var text = ref(props.text)
var isLoadingSave = ref(false);

watch(() => props.text, (val) => {
  text.value = val;
})

const close = () => {
  emits('closed')
}

const send = () => {
  if(!text.value){
    $popup.error('Empty comment');
    return false;
  }

  isLoadingSave.value = true;
  if(props.editedId){
    $api.pathComment(props.editedId, props.type, props.content, {                    
      text: text.value,
      parent_id: props.parentId,
      reply_id: props.replyId
    }) 
    .then((res) => {      
      isLoadingSave.value = false;      
      emits('updated', text.value);                
      $popup.success('Saved');
      text.value = '';
      close();
    })
    .catch(function (error) {        
      isLoadingSave.value = false;         
      $popup.error(error);    
    });  
  }else{
    $api.postComment(props.type, props.content, {
      text: text.value,
      parent_id: props.parentId,
      reply_id: props.replyId
    }) 
    .then((res) => {           
      isLoadingSave.value = false;      
      text.value = '';            
      emits('created', res.data.value.data);    
      $popup.success('Saved');   
      // init();  
    })
    .catch(function (error) {       
      isLoadingSave.value = false;      
      $popup.error(error);               
    });  
  }

}

onMounted(() => {
  if(props.replyToUserName){
    text.value = '@' + props.replyToUserName + ', ';
  }
})

 
</script>
   

<style scoped>
   
.cfrm{
  /* background-color: var(--gd-comment-form-back-color); */
  padding: 1rem 0 0 0;
  border-radius: 5px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;
}




.send_btn {
  display: inline-block;
  width: 46px;
  text-align: center;
  font-size: 1.4rem;
  padding: 5px 0px 12px 0px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}

.send_btn .is_loading {
  display: none;
  position: absolute;
  content: '';
  top: -8px;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.48571429em;
  height: 1.48571429em;
  border-radius: 500rem;
  background-image: url(/images/preloader.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 1;
}

.send_btn .is_ready {
  display: inline-block;
}

.send_btn.loading .is_loading {
  display: inline-block;
}

.send_btn.loading .is_ready {
  display: none;
}


.calcheight{
  width: 100%;
  height: fit-content;
  visibility: hidden;  
  position: absolute;
  white-space: pre-wrap;
  word-wrap: break-word;
  top: 0;
  left: -9999px;
  font-size: 16px!important;
  font-family: monospace;
  padding: 10px;
  line-height: 1.2142em;
}


.input {
  display: inline-block;
  width: 100%;
  position: relative;
}

.input textarea {
  padding: 10px;
  border: 0px;
  width: 100%;
  height: 41px;
  resize: none;
  max-height: 150px;
} 

</style>
